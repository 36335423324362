/*
    Bootstrap 5 uses WCAG 2.0 algorithm for calculating contrast thresholds.
    For migration purpose we lower down the value to prevent from unwanted color changes across stylings for existing job boards
    https://getbootstrap.com/docs/5.0/customize/sass/#color-contrast
*/
$min-contrast-ratio: 1;
$form-bg-color: #fff !default;
$alert-padding-x: 2rem;
$alert-padding-y: 0.5rem;
$small-screen-container-width: 744px !default;
$medium-screen-container-width: 964px !default;
$big-screen-container-width: 1164px !default;
$grid-gutter-width: 24px;
$tooltip-bg: #fff;

.main-form {
    border-radius: 8px;
    background-color: $form-bg-color;
    padding: $grid-gutter-width / 2;
}

.missing-company-data {
    height: 100px;
    padding-left: 10px;
}

.report {
    background: url('../report-sign.png');
    width: 24px;
    height: 24px;
    float: left;
    margin-top: 5px;
    margin-right: 5px;
}

.terms-info {
    font-family: Arial, serif;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #454545;
}

.terms-box {
    margin-bottom: $grid-gutter-width / 2;
}

.col-page-header h1 {
    font-size: 25px;
    margin-top: 0;
}

@import '~bootstrap/scss/bootstrap.scss';

.container,
.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
}

@media (min-width: 768px) {
    .container {
        width: $small-screen-container-width;
    }
}

@media (min-width: 992px) {
    .container {
        width: $medium-screen-container-width;
    }
}

@media (min-width: 1200px) {
    .container {
        width: $big-screen-container-width;
    }
}

.col-xs-12 {
    width: 100%;
}

.company-logo {
    width: 150px;
    padding: 10px;
    margin: 10px 10px 10px 0;
    border: 1px solid #eee;
    border-radius: 3px;
}

.company-missing-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 100px;
    padding: 35px 10px;
    margin: 10px 10px 10px;
    border: 1px solid #eee;
    border-radius: 3px;
    text-align: center;

    .company-missing-logo-text-wrapper {
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 100px;
        line-height: 25px;
    }
}

.job-details-message-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.checkbox input[type='checkbox'],
.radio input[type='radio'] {
    margin-right: ($line-height-base - $form-check-input-width) * 0.5; // line-height minus check height;
}

.form-group {
    margin-bottom: $spacer;
}

.form-group.required .form-label:after,
.asterisk-help:after,
.form-group.required .form-check-label:after,
.form-group.required .form-label:after {
    content: '*';
    color: $danger;
}

h4 {
    border-bottom: 1px solid #b1b1b1;
    padding-bottom: 0.5rem;
}

.longTextElem {
    max-height: 300px;
    overflow: hidden;
    position: relative;
    &.expand {
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100px;
            bottom: 0;
            left: 0;
            right: 0;
            background: -moz-linear-gradient(top, rgba(125, 185, 232, 0) 0%, $form-bg-color 100%);
            background: -webkit-linear-gradient(top, rgba(125, 185, 232, 0) 0%, $form-bg-color 100%);
            background: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, $form-bg-color 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007db9e8', endColorstr='#ffffff', GradientType=0);
        }
    }
}

.logTextCollapseToggle {
    text-align: right;
    margin-top: 5px;
}

label {
    text-align: justify;

    label.form-label {
        display: inline;
        margin-left: 10px;
    }
}

.has-error {
    input,
    select,
    textarea {
        border: 1px solid $danger;
    }
}

.actions-cell {
    display: flex;
    flex-direction: row;
}

.disabled {
    cursor: no-drop;
}

.delete-icon-summary-table {
    cursor: pointer;
}

.delete-icon {
    cursor: pointer;
    margin-left: 0.5rem;
    color: #c32416;
}

.alert-mirage {
    padding: 0.5rem 0.3rem !important;
}

.alert-icon-mirage {
    margin: auto 0.3rem auto 0;
}

.tooltip-icon {
    color: $primary;
}

.tooltip-inner {
    box-sizing: border-box;
    max-width: 18rem;
    margin: 0;
    background-color: #fff;
    color: #3a434f;
    border: 1px solid #dcdfe2;
    border-radius: 3px;
    padding: 24px 24px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15);
}

.tooltip.show {
    opacity: 1;
}

.form-content-padded {
    @include make-col(12);

    @include media-breakpoint-up(sm) {
        @include make-col(8);
        @include make-col-offset(2);
    }
}

.form-control {
    transition: none;

    &:hover {
        border-color: #ccc;
    }
}

input[type='date'] {
    cursor: text;

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }
}
